import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { tagIsActive } from '@/feature-flipping/services/featureFlippingService'
import type { FeatureFlippingTagEnum } from '@/feature-flipping/models/featureFlippingFlagEnum'

export const useFeatureFlippingGuard = () => {
  const checkFeatureAccess = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.meta.featureFlippingTag) {
      const tag = to.meta.featureFlippingTag as FeatureFlippingTagEnum
      try {
        const isActive = await tagIsActive(tag.toString())
        if (!isActive) {
          next({ name: 'featureNotAvailableView' })
        } else {
          next()
        }
      } catch {
        next({ name: 'featureNotAvailableView' })
      }
    }
  }

  return {
    checkFeatureAccess
  }
}
