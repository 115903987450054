import { http } from '@/common/api'
import type { Page } from '@/common/models/Page'
import type { FeatureFlipping } from '../models/featureFlipping'

export const getFeatureFlippingTags = (
  page = 0,
  size = 0,
  trackId = 'get-feature-flipping-tags'
): Promise<Page<FeatureFlipping>> => {
  const params = new URLSearchParams()

  if (page > 0) {
    params.append('page', page.toString())
  }
  if (size > 0) {
    params.append('size', size.toString())
  }

  return http.get('/feature-flipping', { params, trackId }).then((response) => {
    const res = response.data
    res.content.forEach((element: FeatureFlipping) => {
      if (element.startingDateApplication) {
        element.startingDateApplication = new Date(element.startingDateApplication)
      }

      if (element.endingDateApplication) {
        element.endingDateApplication = new Date(element.endingDateApplication)
      }
    })
    return res
  })
}

export const createFeatureFlipping = (
  featureFlipping: FeatureFlipping,
  trackId = 'create-feature-flipping'
): Promise<FeatureFlipping> => {
  return http
    .post('/feature-flipping', { ...featureFlipping }, { trackId })
    .then((response) => response.data)
}

export const updateFeatureFlipping = (
  featureFlipping: FeatureFlipping,
  trackId = 'update-feature-flipping'
): Promise<FeatureFlipping> => {
  return http
    .put(`/feature-flipping/${featureFlipping.id}`, { ...featureFlipping }, { trackId })
    .then((response) => response.data)
}

export const deleteFeatureFlipping = (
  featureFlipping: FeatureFlipping,
  trackId = 'delete-feature-flipping'
): Promise<void> => {
  return http
    .delete(`/feature-flipping/${featureFlipping.id}`, { trackId })
    .then((response) => response.data)
}

export const tagIsActive = (tagName: string, trackId = 'tag-is-active'): Promise<boolean> => {
  return http
    .get(`/public/feature-flipping/${tagName}/is-active`, { trackId })
    .then((response) => response.data)
}
