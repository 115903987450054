import { createRouter, createWebHistory } from 'vue-router'
import accountRoutes from '@/account/routes'
import clientRoutes from '@/client/routes'
import projectRoutes from '@/project/routes'
import documentRoutes from '@/document/routes'
import billingRoutes from '@/billing/routes'
import { Layout } from '@/common/layouts'
import { userRoutes } from '@/user/routes'
import { useAuthGuard } from '@/common/composables/useAuthGuard'
import { FeatureFlippingTagEnum } from '@/feature-flipping/models/featureFlippingFlagEnum'
import { useFeatureFlippingGuard } from '@/common/composables/useFeatureFlippingGuard'

const { checkAccountExist } = useAuthGuard()
const { checkFeatureAccess } = useFeatureFlippingGuard()

declare module 'vue-router' {
  interface RouteMeta {
    layout?: Layout // default Public
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash }
    } else {
      return { top: 0 }
    }
  },
  routes: [
    ...accountRoutes,
    {
      path: '/espace-admin',
      name: 'adminPage',
      meta: { layout: Layout.Private, role: 'client' },
      component: () => import('@/common/views/AdminPage.vue'),
      redirect: {
        name: 'dashboard'
      },
      children: [
        {
          path: 'tableau-de-bord',
          name: 'dashboard',
          component: () => import('@/common/views/TheDashboard.vue')
        },
        ...clientRoutes,
        ...projectRoutes,
        ...documentRoutes,
        ...billingRoutes,
        {
          path: 'artisans',
          name: 'artisans',
          component: () => import('@/artisan/views/ArtisanList.vue')
        },
        {
          path: 'entreprises-artisans',
          name: 'artisansCompanies',
          component: () => import('@/artisan/views/ArtisanCompanyList.vue')
        },
        {
          path: 'entreprises-diagnostiqueurs',
          name: 'diagnosticiansCompanies',
          component: () => import('@/diagnostician/views/DiagnosticianCompanyList.vue')
        },
        {
          path: 'accompagnateurs',
          name: 'accompanists',
          component: () => import('@/accompanist/views/AccompanistList.vue')
        },
        {
          path: 'entreprises-accompagnateur',
          children: [
            {
              path: '',
              name: 'accompanistsCompanies',
              component: () => import('@/accompanist/views/AccompanistCompanyList.vue')
            },
            {
              path: ':id/zone-intervention',
              name: 'accompanistCompanyOperationalArea',
              component: () => import('@/accompanist/views/AccompanistCompanyOperationalArea.vue')
            }
          ]
        },
        {
          path: 'agences-accompagnateur',
          children: [
            {
              path: '',
              name: 'accompanistsAgencies',
              component: () => import('@/accompanist/views/AccompanistAgencyList.vue')
            },
            {
              path: ':id/zone-intervention',
              name: 'accompanistAgencyOperationalArea',
              component: () => import('@/accompanist/views/AccompanistAgencyOperationalArea.vue')
            }
          ]
        },
        {
          path: 'agences-professionnels',
          name: 'professionalAgencies',
          component: () => import('@/artisan/views/ProfessionalAgencyList.vue')
        },
        {
          path: 'agences-diagnostiqueurs',
          name: 'diagnosticiansAgencies',
          component: () => import('@/diagnostician/views/DiagnosticianAgencyList.vue')
        },
        {
          path: 'carte-partenaires',
          name: 'partnerMap',
          component: () => import('@/person/views/PartnerMap.vue')
        },
        {
          path: 'gestion-feature-flipping',
          name: 'featureFlippingView',
          component: () => import('@/feature-flipping/views/FeatureFlippingView.vue')
        },
        {
          path: 'kpi-metier',
          name: 'activityKpiView',
          beforeEnter: checkFeatureAccess,
          meta: {
            featureFlippingTag: FeatureFlippingTagEnum.AFFICHAGE_KPI
          },
          component: () => import('@/activity-kpi/views/ActivityKpi.vue')
        },
        {
          path: 'kpi-marketing',
          name: 'activityKpiMarketingView',
          component: () => import('@/activity-kpi/views/ActivityKpiMarketing.vue')
        },
        {
          path: 'redressement-des-donnees',
          name: 'recoveryPage',
          component: () => import('@/common/views/RecoveryPage.vue')
        },
        ...userRoutes
      ]
    },
    {
      path: '/error',
      name: 'errorsPage',
      component: () => import('@/common/views/ErrorsPage.vue')
    },
    {
      path: '/verification-redirection',
      name: 'checkRedirectPage',
      beforeEnter: checkAccountExist,
      component: () => import('@/common/views/CheckRedirectPage.vue')
    },
    {
      path: '/feature-non-disponible',
      name: 'featureNotAvailableView',
      meta: { hideFooter: true },
      component: () => import('@/common/views/FeatureNotAvailable.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: { name: 'sign-in' } }
  ]
})

export default router
